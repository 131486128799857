<template>
  <nav>
    <ul class="footer-legal">
      <li
        v-for="(link, idx) in legalLinks"
        :key="idx"
      >
        <NuxtLink
          v-if="link.page"
          :to="localePath(link?.page?.url_path)"
        >
          {{ link.page?.title }}
        </NuxtLink>
        <NuxtLink
          v-else
          :to="link.external_link?.url"
          target="_blank"
          external
        >
          {{ link?.external_link?.title }}
        </NuxtLink>
      </li>

      <li v-show="isConsentGiven">
        <button @click="onClick">
          {{ $t('configure_cookies') }}
        </button>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { useLocalePath, useCookieControl } from '#imports';

const { isModalActive, isConsentGiven } = useCookieControl();
const localePath = useLocalePath();

type Page = {
  id: number
  title: string
  url_path: string
  slug: string
};

type LegalLink = {
  page: Page | null
  external_link: {
    title?: string
    url?: string
  }
};

interface Props {
  legalLinks: LegalLink[]
}
defineProps<Props>();

function onClick () {
  isModalActive.value = true;
}
</script>

<style scoped>
.footer-legal {
  @apply flex gap-x-4 xl:justify-end flex-wrap;
}
</style>
